
import { SectionQuery } from "@/api/requests/sections/SectionQuery";
import { GetSectionResponse } from "@/api/responses/sections/GetSectionResponse";
import ACTIONS from "@/store/action-definitions";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

@Component({
	components: {}
})
export default class SectionsTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: this.$t("sections.Id")
		},
		{
			prop: "name",
			label: this.$t("sections.Name"),
			minWidth: 200
		},
		{
			prop: "numberOfReports",
			label: this.$t("sections.NoOfReports"),
			minWidth: 200
		},
		{
			prop: "isSelectableRow",
			hidden: true
		}
	];
	private tableData = {
		results: [],
		count: 10,
		currentPage: 1
	} as TableData<GetSectionResponse>;
	private perPageOptions = [5, 10, 15];
	private loading = true;
	private selectedRows: Array<number> = [];
	private pagination = {
		page: 1,
		pageSize: 5,
		sortDirection: "descending",
		sortColumn: this.tableColumns[0].prop,
		searchQuery: "",
		shouldSort: false
	} as SectionQuery;

	@Action(ACTIONS.SET_SHOW_CONFIMRATION_MODAL) setShowConfimrationModal: (
		show: boolean
	) => void;

	@Getter("getStatusConfirmationModal") statusConfirmationModal!: boolean;

	@Watch("statusConfirmationModal")
	async onStatusConfirmationModalChange(newVal: boolean) {
		if (newVal === true) {
			if (this.selectedRows.length) {
				const { success } = await this.$sections.deleteSections(
					this.selectedRows
				);
				if (success) {
					this.selectedRows = [];
					this.fetchData(this.pagination);
				}
			}
		}
	}

	async fetchData(pagination: SectionQuery): Promise<void> {
		this.loading = true;
		this.tableData.results.length = 0;
		const { data, success } = await this.$sections.getSections(pagination);
		if (success) {
			data.results.forEach((section) => {
				section.isSelectableRow = section.numberOfReports === 0;
			});
			this.tableData = data;
		}
		this.loading = false;
	}

	async handleRemove(id: number) {
		this.selectedRows = [id];
		this.setShowConfimrationModal(true);
	}

	async handleRemoveSelected() {
		this.setShowConfimrationModal(true);
	}

	rowSelected(data: Array<GetSectionResponse>) {
		this.selectedRows = data.map((section) => section.id);
	}
}
