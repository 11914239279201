
import { Component, Vue } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import { TableQuery } from "@/api/requests/TableQuery";
import { GetCountriesResponse } from "@/api/responses/countries/GetCountriesResponse";
import { GetAccountsResponse } from "@/api/responses/accounts/GetAccountsResponse";
import { GetAccountByIdResponse } from "@/api/responses/accounts/GetAccountByIdResponse";
import ACTIONS from "@/store/action-definitions";
import { Action } from "vuex-class";
// import { CreateAccountRequest } from "@/api/requests/accounts/CreateAccountRequest";
// import { UpdateAccountRequest } from "@/api/requests/accounts/UpdateAccountRequest";

@Component
export default class AccountForm extends Vue {
	private data = this.getEmptyAccount();
	private errorMessage = "";
	private isCreateForm = false;
	private loading = false;
	private graphOptions = [
		{ label: "3 Graphs", value: 3 },
		{ label: "2 Graphs", value: 2 }
	];
	private graphResults = [
		{ label: "Graph 3", value: 3 },
		{ label: "Graph 2", value: 2 },
		{ label: "Graph 1", value: 1 }
	];
	private graphStyles = [
		{ label: "PK3 style graphs", value: 3 },
		{ label: "App2 style graphs", value: 2 }
	];
	private selectedAssessmentApps = [
		{ label: "APP2", value: 0 },
		{ label: "APP4", value: 1 }
	];
	private countryChoosen = "";
	private parentAccountChoosen = "";
	private activeTab = "details";

	$refs!: {
		form: Validatorable;
	};

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	async checkForm(): Promise<void> {
		this.clearErrors();
		const isValid = await this.$refs.form.validate();
		if (isValid) await this.saveChanges();
	}

	async saveChanges(): Promise<void> {
		this.setMessage({
			message: "Save is not yet available for accounts",
			type: "warning"
		});
		// let isSuccess = false;
		// if (this.isCreateForm) {
		// 	isSuccess = (
		// 		await this.$accounts.createAccount({
		// 			id: this.data.id,
		// 			name: this.data.name,
		// 			description: this.data.description,
		// 			graphOption: this.data.graphOption,
		// 			graphResults: this.data.graphResults,
		// 			graphStyle: this.data.graphStyle,
		// 			assessmentLimit: this.data.assessmentLimit,
		// 			reportBalance: this.data.reportBalance,
		// 			allowBenchmarks: this.data.allowBenchmarks,
		// 			hasBenchmarkCreatorAccess: this.data
		// 				.hasBenchmarkCreatorAccess,
		// 			allowOccupationalKeys: this.data.allowOccupationalKeys,
		// 			allowCustomization: this.data.allowCustomization,
		// 			allowCustomReports: this.data.allowCustomReports,
		// 			allowEEOC: this.data.allowEEOC,
		// 			allowContactInfo: this.data.allowContactInfo,
		// 			allowSUKCs: this.data.allowSUKCs,
		// 			allowDistCodes: this.data.allowDistCodes,
		// 			allowTracking: this.data.allowTracking,
		// 			encryptLinks: this.data.encryptLinks,
		// 			allowSGI: this.data.allowSGI,
		// 			allowTeamRadar: this.data.allowTeamRadar,
		// 			allowGroupRpt: this.data.allowGroupRpt,
		// 			payForChildren: this.data.payForChildren,
		// 			allowUsageBilling: this.data.allowUsageBilling,
		// 			dateLimit: this.data.dateLimit,
		// 			taxExtempt: this.data.taxExtempt,
		// 			country: this.data.country,
		// 			parentAccount: this.data.parentAccount,
		// 			wLSEnabled: this.data.wLSEnabled,
		// 			widgetAccessEnabled: this.data.widgetAccessEnabled,
		// 			apiEnabled: this.data.apiEnabled,
		// 			apiKey: this.data.apiKey,
		// 			paymentProfileID: this.data.paymentProfileID,
		// 			internationalPaymentProfileID: this.data.internationalPaymentProfileID,
		// 			notes: this.data.notes,
		// 			selectedAssessmentApp: this.data.selectedAssessmentApp
		// 		} as CreateAccountRequest)
		// 	).success;
		// } else {
		// 	isSuccess = (
		// 		await this.$accounts.updateAccount(this.data.id, {
		// 			id: this.data.id,
		// 			name: this.data.name,
		// 			description: this.data.description,
		// 			graphOption: this.data.graphOption,
		// 			graphResults: this.data.graphResults,
		// 			graphStyle: this.data.graphStyle,
		// 			assessmentLimit: this.data.assessmentLimit,
		// 			reportBalance: this.data.reportBalance,
		// 			allowBenchmarks: this.data.allowBenchmarks,
		// 			hasBenchmarkCreatorAccess: this.data
		// 				.hasBenchmarkCreatorAccess,
		// 			allowOccupationalKeys: this.data.allowOccupationalKeys,
		// 			allowCustomization: this.data.allowCustomization,
		// 			allowCustomReports: this.data.allowCustomReports,
		// 			allowEEOC: this.data.allowEEOC,
		// 			allowContactInfo: this.data.allowContactInfo,
		// 			allowSUKCs: this.data.allowSUKCs,
		// 			allowDistCodes: this.data.allowDistCodes,
		// 			allowTracking: this.data.allowTracking,
		// 			encryptLinks: this.data.encryptLinks,
		// 			allowSGI: this.data.allowSGI,
		// 			allowTeamRadar: this.data.allowTeamRadar,
		// 			allowGroupRpt: this.data.allowGroupRpt,
		// 			payForChildren: this.data.payForChildren,
		// 			allowUsageBilling: this.data.allowUsageBilling,
		// 			dateLimit: this.data.dateLimit,
		// 			taxExtempt: this.data.taxExtempt,
		// 			country: this.data.country,
		// 			parentAccount: this.data.parentAccount,
		// 			wLSEnabled: this.data.wLSEnabled,
		// 			widgetAccessEnabled: this.data.widgetAccessEnabled,
		// 			apiEnabled: this.data.apiEnabled,
		// 			apiKey: this.data.apiKey,
		// 			paymentProfileID: this.data.paymentProfileID,
		// 			internationalPaymentProfileID: this.data.internationalPaymentProfileID,
		// 			notes: this.data.notes,
		// 			selectedAssessmentApp: this.data.selectedAssessmentApp
		// 		} as UpdateAccountRequest)
		// 	).success;
		// }
		// if (isSuccess) {
		// 	if (this.isCreateForm) {
		// 		this.setMessage({
		// 			message: this.$t("accounts.SuccessfullyCreated").toString(),
		// 			type: "success"
		// 		});
		// 	} else {
		// 		this.setMessage({
		// 			message: this.$t("accounts.SuccessfullyUpdated").toString(),
		// 			type: "success"
		// 		});
		// 	}
		// 	this.$emit("closeModal");
		// }
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}

	getEmptyAccount(): GetAccountByIdResponse {
		return {
			id: 0,
			name: "",
			description: "",
			graphOption: 3,
			graphResults: 3,
			graphStyle: 3,
			assessmentLimit: 0,
			reportBalance: 0,
			allowBenchmarks: false,
			hasBenchmarkCreatorAccess: false,
			allowOccupationalKeys: false,
			allowCustomization: false,
			allowCustomReports: false,
			allowEEOC: false,
			allowContactInfo: false,
			allowSUKCs: false,
			allowDistCodes: false,
			allowTracking: false,
			encryptLinks: true,
			allowSGI: false,
			allowTeamRadar: false,
			allowGroupRpt: false,
			payForChildren: false,
			allowUsageBilling: false,
			dateLimit: undefined,
			taxExtempt: false,
			country: undefined,
			parentAccount: 0,
			wLSEnabled: false,
			widgetAccessEnabled: false,
			apiEnabled: false,
			apiKey: "",
			limitByDollarAmount: 0,
			paymentProfileID: undefined,
			internationalPaymentProfileID: undefined,
			notes: "",
			selectedAssessmentApp: 1,
			statisticsAllTime: {
				sumOfPurchasesUsage: 0,
				sumOfPurchasedAssessments: 0,
				sumOfPurchasedGroupReports: 0,
				totalSumOfPurchases: 0
			},
			statisticsLastMonth: {
				sumOfPurchasesUsage: 0,
				sumOfPurchasedAssessments: 0,
				sumOfPurchasedGroupReports: 0,
				totalSumOfPurchases: 0
			}
		} as GetAccountByIdResponse;
	}

	clear(): void {
		this.loading = true;
		this.isCreateForm = true;
		this.data = this.getEmptyAccount();
		this.countryChoosen = "";
		this.parentAccountChoosen = "";
		this.loading = false;
		this.activeTab = "details";
	}

	async fill(id: number): Promise<void> {
		this.loading = true;
		this.isCreateForm = false;
		this.activeTab = "details";
		if (id > 0) {
			this.data = this.getEmptyAccount();
			const { data, success } = await this.$accounts.getAccountById(id);
			if (success) {
				this.data = data;
				if (data.parentAccount > 0) {
					const accountResponse = await this.$accounts.getAccountById(
						data.parentAccount
					);
					if (accountResponse.success) {
						this.parentAccountChoosen = accountResponse.data.name;
					}
				} else {
					this.parentAccountChoosen = "";
				}
				if (data.country != null && data.country > 0) {
					const countryResponse = await this.$countries.getCountryById(
						data.country
					);
					if (countryResponse.success) {
						this.countryChoosen = countryResponse.data.country;
					}
				} else {
					this.countryChoosen = "";
				}
				this.loading = false;
			}
		}
	}

	async getCountries(
		queryString: string,
		callBack: (data: GetCountriesResponse[]) => void
	): Promise<void> {
		const { data, success } = await this.$countries.getCountries({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "NumericCode",
			searchQuery: queryString,
			shouldSort: false
		} as TableQuery);
		if (success) {
			callBack(data.results);
		}
	}

	handleSelectCountry(item: GetCountriesResponse): void {
		this.countryChoosen = item.country;
		this.data.country = item.numericCode;
	}

	async getParentAccounts(
		queryString: string,
		callBack: (data: GetAccountsResponse[]) => void
	): Promise<void> {
		const { data, success } = await this.$accounts.getAccounts({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "id",
			searchQuery: queryString,
			shouldSort: false
		} as TableQuery);
		if (success) {
			callBack(data.results);
		}
	}

	handleSelectParentAccount(item: GetAccountsResponse): void {
		this.parentAccountChoosen = item.name;
		this.data.parentAccount = item.id;
	}

	async generateApiKey(): Promise<void> {
		const { data, success } = await this.$accounts.generateApiKey();
		if (success) {
			this.data.apiKey = data;
		}
	}

	handleRedirectTo(path: string) {
		this.$router.push({
			name: `${path}`,
			params: { accountId: `${this.data.id}` }
		});
	}

	redirectToLbda() {
		// later it will be changed to dashboard internal link
		window.open(
			`https://testapp2.peoplekeys.com/Admin_AccountBalanceManagement.aspx?id=${this.data.id}`,
			"_blank"
		);
	}

	redirectToPurchaseReport() {
		// later it will be changed to dashboard internal link
		window.open(
			`https://testapp2.peoplekeys.com/Admin_PurchaseReport.aspx?id=${this.data.id}`,
			"_blank"
		);
	}
}
