import { ReportDataDto } from "@/api/responses/reports/dtos/ReportDataDto";

export function buildHtml(reportData: ReportDataDto) {
	let html = "";
	html = "<!DOCTYPE HTML>\r\n\t";
	html += "<html>\r\n\t\t";
	html += "<head>\r\n\t\t\t";
	html += '<meta charset="UTF-8" />\r\n\t\t\t';
	html += "<title>\r\n\t\t\t\tPeopleKeys report\r\n\t\t\t</title>";
	html += reportData.resources + "\r\n\t\t\t";
	html += reportData.scripts + "\r\n\t\t";
	html += "</head>\r\n\t\t";
	html += "<body>\r\n\t\t\t";
	reportData.sections.forEach((element) => {
		html += element.content + "\n";
	});
	html += reportData.bodyScripts;
	html += "</body>\n";
	html += "</html>\r\n";
	html += "</!DOCTYPE html>\r\n";

	return html;
}
