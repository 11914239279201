
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import { EditableSection } from "@/types/Editor/EditableSection";
import { Getter } from "vuex-class";
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { GetSectionResponse } from "@/api/responses/sections/GetSectionResponse";

@Component
export default class SectionModal extends Vue {
	@Prop({ type: String }) type!: string;
	@Prop({ type: Boolean }) showModal!: boolean;
	@Prop({ type: Array }) assessmentTypes!: Array<GetAssessmentsTypeResponse>;
	@Prop({ type: Object }) section!: EditableSection;

	@Getter("getEditorSections") sectionses!: ReportSectionDto[];

	private isVisible = false;
	private editableSection: EditableSection = {
		id: 0,
		name: "",
		assessmentType: undefined,
		reportId: +this.$route.query.reportId,
		addTemplate: false
	};
	private radio = "1";
	private sectionChoosen = "";
	private selectedSection: EditableSection | undefined;
	private sectionAlreadyExist = false;
	private cloneSection = false;
	private clonedSectionName = "";

	get isDataValid() {
		return this.radio === "1"
			? this.editableSection.name && this.editableSection.assessmentType
			: this.sectionChoosen !== "" &&
					(this.cloneSection ? this.clonedSectionName !== "" : true);
	}

	resetModal(): void {
		this.sectionChoosen = "";
		this.clonedSectionName = "";
		this.radio = "1";
		this.cloneSection = false;
	}

	handleSelectSection(item: GetSectionResponse): void {
		if (this.sectionses.some((section) => section.id === item.id)) {
			this.sectionAlreadyExist = true;
			this.sectionChoosen = "";
			return;
		}
		this.sectionChoosen = item.name;
		this.selectedSection = {
			id: item.id,
			name: item.name,
			assessmentType: {
				id: item.assessmentTypeId,
				name: item.assessmentType,
				description: "",
				assessmentStyles: []
			},
			reportId: +this.$route.query.reportId,
			addTemplate: false
		};
		this.sectionAlreadyExist = false;
	}

	async getFilteredSections(
		queryString: string,
		callBack: (data: GetSectionResponse[]) => void
	): Promise<void> {
		const { data, success } = await this.$sections.getGlobalSections({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "id",
			searchQuery: queryString,
			shouldSort: false,
			name: queryString
		});
		if (success) {
			callBack(data.results);
		}
	}

	@Emit("onClose")
	onModalClose() {
		return;
	}

	@Emit("onClone")
	onSectionClone() {
		return {
			sectionId: this.selectedSection?.id,
			cloneSectionRequest: {
				reportId: +this.$route.query.reportId,
				sectionName: this.clonedSectionName
			}
		};
	}

	@Emit("onSave")
	onSectionSave() {
		let isUpdated = this.editableSection.id > 0;
		let sectionToSave = this.editableSection;
		if (this.radio === "2" && this.selectedSection) {
			isUpdated = false;
			sectionToSave = this.selectedSection;
		}
		return { updated: isUpdated, section: sectionToSave };
	}

	@Watch("section")
	onSectionChange(newVal: EditableSection) {
		this.editableSection = newVal;
	}

	@Watch("showModal")
	onModalVisibilityChange(newVal: boolean) {
		this.isVisible = newVal;
		this.resetModal();
	}

	@Watch("radio")
	onRadioChange() {
		this.editableSection = {
			id: 0,
			name: "",
			assessmentType: undefined,
			reportId: +this.$route.query.reportId,
			addTemplate: false
		};

		this.selectedSection = undefined;
		this.sectionChoosen = "";
	}
}
