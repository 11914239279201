
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class PkButton extends Vue {
	@Prop({ type: String, default: "button" }) tag!: string;
	@Prop({ type: String, default: "default" }) type!: string;
	@Prop({ type: String, default: "button" }) nativeType!: string;
	@Prop({ type: Boolean }) round!: boolean;

	@Prop({ type: Boolean }) block!: boolean;

	@Prop({ type: Boolean }) social!: boolean;

	@Prop({ type: Boolean }) link!: boolean;

	@Prop({ type: Boolean }) icon!: boolean;

	@Prop({ type: Boolean }) wide!: boolean;

	@Prop({ type: String }) size!: string;

	@Prop({ type: Boolean }) disabled!: boolean;

	@Prop({ type: Boolean }) neutral!: boolean;

	get classes() {
		const btnClasses = [
			{ "btn-icon": this.icon },
			{ "btn-round": this.round },
			{ "btn-block": this.block },
			{ "btn-social": this.social },
			{ "btn-link": this.link },
			{ "btn-wd": this.wide },
			{ "btn-neutral": this.neutral },
			`btn-${this.type}`
		];
		if (this.size) {
			btnClasses.push(`btn-${this.size}`);
		}
		return btnClasses;
	}
}
