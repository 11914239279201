
import { Vue, Component, Prop, Watch, Emit } from "vue-property-decorator";
import RulesList from "../content/RulesList.vue";
import * as _ from "lodash";
import { RuleSet } from "@/types/RuleSet";
import { Validatorable } from "@/interfaces/Validatorable";
import { TableQuery } from "@/api/requests/TableQuery";
import i18n from "@/locales/i18n";
import { Translation } from "@/types/TextResources/Form/Translation";
import { SelectItem } from "@/types/TextResources/Form/SelectItem";
import { SaveTextResourceRequest } from "@/api/requests/textResource/SaveTextResourceRequest";
import { GetTextResourceResponse } from "@/api/responses/textResources/GetTextResourceResponse";
import { RuleToEdit } from "@/types/TextResources/Form/RuleToEdit";
import { Language } from "@/types/Language";
import { Rule } from "@/types/Rule";
import { TextResourceTranslation } from "@/types/TextResourceTranslation";
import ACTIONS from "@/store/action-definitions";
import { Action } from "vuex-class";
import { AddTextResourceRequest } from "@/api/requests/textResource/AddTextResourceRequest";

@Component({
	components: {
		RulesList
	}
})
export default class TextResourceForm extends Vue {
	@Prop({ type: Boolean }) showModal!: boolean;
	@Prop({ type: Object }) textResource!: GetTextResourceResponse;

	$refs!: {
		form: Validatorable;
	};

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	private data: GetTextResourceResponse = this.textResource;
	private newTranslationLang: Language | undefined;
	private selectedLanguageIndex = 0;
	private ruleSetList = [] as Array<RuleSet>;
	private availableLanguages = [] as Array<SelectItem>;
	private languageChoosen = "";
	private loaded = false;
	private showPopover = false;
	private languagesNumber = 0;

	private get clearData(): GetTextResourceResponse {
		return {
			id: 0,
			name: "",
			rules: [],
			textResourceTranslations: []
		};
	}

	private get defaultLanguageVal() {
		return {
			value: 0,
			label: "Select",
			index: 0
		};
	}

	togglePopover() {
		this.showPopover = !this.showPopover;
	}

	extractRules(textResource: GetTextResourceResponse): Array<RuleSet> {
		const groupedRules = [];
		const ruleSetGroup = _.groupBy(textResource.rules, (item: Rule) => {
			return item.ruleSet?.id, item.ruleSet?.name;
		});

		for (const group in ruleSetGroup) {
			const ruleSet: RuleSet = {
				id: ruleSetGroup[group][0].ruleSet.id,
				name: group,
				rules: ruleSetGroup[group].map((rule: Rule) => {
					return {
						rule: rule.rule,
						id: rule.id,
						name: rule.name
					};
				})
			};

			groupedRules.push(ruleSet);
		}
		return groupedRules;
	}

	deleteRule(deletedElement: RuleToEdit): void {
		this.ruleSetList[deletedElement.ruleSetIndex].rules.splice(
			deletedElement.ruleIndex,
			1
		);
	}

	saveRule(editedElement: RuleToEdit): void {
		const rule = this.ruleSetList[editedElement.ruleSetIndex].rules[
			editedElement.ruleIndex
		];
		if (rule) {
			rule.name = editedElement.rule.ruleName;
			rule.rule = editedElement.rule.rule;
		}
	}

	async getLanguages(
		queryString: string,
		callBack: (data: Array<Language>) => void
	): Promise<void> {
		const { data, success } = await this.$languages.getLanguages({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "name",
			searchQuery: queryString,
			shouldSort: false
		} as TableQuery);
		if (success) {
			callBack(
				data.results.filter(
					(lang) =>
						!this.availableLanguages.some(
							(availableLang) => availableLang.value === lang.id
						)
				)
			);
			this.loaded = true;
		}
	}

	hasTranslation(language: Language): boolean {
		return this.availableLanguages.some(
			(lang) => lang.value === language.id
		);
	}

	addTranslation(): void {
		if (this.newTranslationLang) {
			if (this.hasTranslation(this.newTranslationLang)) {
				return;
			}
			const translationDictItem = {
				index: this.availableLanguages.length,
				label: this.newTranslationLang.name,
				value: this.newTranslationLang.id
			};
			if (
				this.availableLanguages.length === 1 &&
				this.availableLanguages[0].value === 0
			) {
				this.availableLanguages.splice(0);
				translationDictItem.index = 0;
			}
			this.availableLanguages.push(translationDictItem);

			const newTranslationObject: Translation = {
				id: 0,
				text: "",
				languageId: translationDictItem.value,
				language: this.newTranslationLang
			};

			this.data.textResourceTranslations.push(newTranslationObject);
			this.setLanguage(translationDictItem.index);

			this.newTranslationLang = undefined;
			this.languageChoosen = "";
		}
	}

	removeTranslation(): void {
		this.data.textResourceTranslations.splice(
			this.selectedLanguageIndex,
			1
		);
		this.availableLanguages.splice(this.selectedLanguageIndex, 1);
		this.availableLanguages.forEach((language, index) => {
			language.index = index;
		});

		if (!this.availableLanguages.length) {
			this.availableLanguages = [this.defaultLanguageVal];
		}
		this.setLanguage(this.selectedLanguageIndex - 1);
	}

	setLanguage(index: number): void {
		this.selectedLanguageIndex = index < 0 ? 0 : index;
	}

	resetLanguage(): void {
		this.selectedLanguageIndex = 0;
	}

	handleSelectLanguage(item: Language): void {
		this.showPopover = false;
		this.languageChoosen = item.name;
		this.newTranslationLang = item;
		this.addTranslation();
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}

	resetModal(): void {
		this.data = this.clearData;
		this.ruleSetList.splice(0);
		this.availableLanguages = [this.defaultLanguageVal];

		this.languageChoosen = "";
		this.resetLanguage();
		this.loaded = false;
	}

	async saveForm(): Promise<void> {
		this.clearErrors();
		const resourceToSave: SaveTextResourceRequest = {
			name: this.data.name,
			updateRules: true,
			textResourcesTranslations: this.data.textResourceTranslations.map(
				(translation: TextResourceTranslation) => {
					return {
						languageId: translation.language.id,
						text: translation.text
					};
				}
			),
			textResourcesRules: this.ruleSetList
				.map((rulSet) =>
					rulSet.rules.map((rule) => {
						return {
							ruleId: rule.id,
							ruleName: rule.name,
							rule: rule.rule,
							ruleSetId: rulSet.id
						};
					})
				)
				.flat(2)
		};

		const isValid = await this.$refs.form.validate();
		if (isValid) {
			if (!this.data.textResourceTranslations.length) {
				this.setMessage({
					message: i18n
						.t("textResources.EmptyTranslations")
						.toString(),
					type: "danger"
				});
				return;
			}
			let saveMethod = null;
			if (this.data.id !== 0) {
				saveMethod = this.$textResources.saveTextResource(
					this.data.id,
					resourceToSave
				);
			} else {
				saveMethod = this.$textResources.addTextResource({
					name: resourceToSave.name,
					sectionId: 0,
					textResourcesTranslations:
						resourceToSave.textResourcesTranslations
				} as AddTextResourceRequest);
			}

			const { success } = await saveMethod;
			if (success) {
				this.setMessage({
					message: i18n
						.t("textResources.SuccessfullySaved")
						.toString(),
					type: "success"
				});
				this.closeModal();
			}
		}
	}

	@Emit("closeModal")
	closeModal() {
		this.loaded = false;
		this.resetModal();
		return;
	}

	@Watch("textResource")
	onResourceChange(newVal: GetTextResourceResponse) {
		this.resetModal();
		this.data = newVal;
		if (newVal.id !== 0) {
			this.availableLanguages = this.data.textResourceTranslations.map(
				(translation: TextResourceTranslation, index: number) => {
					return {
						index: index,
						value: translation.language.id,
						label: translation.language.name
					};
				}
			);

			this.resetLanguage();
			this.ruleSetList = this.extractRules(newVal);
			this.loaded = true;
		}
	}

	async created() {
		const { success, data } = await this.$languages.getLanguages({
			page: 1,
			pageSize: 10,
			sortDirection: "desc",
			sortColumn: "",
			searchQuery: "",
			shouldSort: true
		});

		if (success) this.languagesNumber = data.count;

		this.data = this.clearData;
		this.resetLanguage();
	}
}
