
import { Component, Vue } from "vue-property-decorator";

@Component
export default class CallbackPage extends Vue {
	constructor() {
		super();
		this.$auth.callbackSignIn().then((result) => {
			if (result.success) {
				this.$router.push({
					name: result.postLoginRedirectUri
						? result.postLoginRedirectUri
						: "home"
				});
			}
		});
	}
}
