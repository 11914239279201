
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import ACTIONS from "@/store/action-definitions";
import { CreateLanguageRequest } from "@/api/requests/languages/CreateLanguageRequest";
import { Action, Getter } from "vuex-class";
import { Language } from "@/types/Language";

@Component
export default class LanguageForm extends Vue {
	private data: Language = {
		id: 0,
		name: "",
		code: "",
		isDefault: false
	};
	private errorMessage = "";
	private loading = false;

	$refs!: {
		form: Validatorable;
	};

	@Getter("getEditedLanguage") editedLanguage!: Language;

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	resetModal(): void {
		this.data = {
			id: 0,
			name: "",
			code: "",
			isDefault: false
		};
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}

	async saveForm(): Promise<void> {
		this.clearErrors();
		const languageToAdd: CreateLanguageRequest = {
			name: this.data.name,
			code: this.data.code
		};
		const isValid = await this.$refs.form.validate();
		if (isValid) {
			let saveMethod = null;
			if (this.data.id === 0) {
				saveMethod = this.$languages.createLanguage(languageToAdd);
			} else {
				saveMethod = this.$languages.updateLanguage(
					this.data.id,
					languageToAdd
				);
			}
			const { success } = await saveMethod;
			if (success) {
				this.setMessage({
					message: this.$t("languages.SuccessfullySaved").toString(),
					type: "success"
				});
				this.closeModal();
			}
		}
	}

	@Emit("closeModal")
	closeModal() {
		this.resetModal();
		return;
	}

	@Watch("data.code")
	onCodeChange(newVal: string, oldVal: string) {
		if (newVal.length === 2 && newVal > oldVal) this.data.code += "-";
	}

	@Watch("editedLanguage")
	onEditedLanguageChange(newVal: Language) {
		if (newVal) {
			this.data = newVal;
		} else {
			this.resetModal();
		}
	}
}
