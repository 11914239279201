
import { TableQuery } from "@/api/requests/TableQuery";
import { CdnFile } from "@/types/CdnFile";
import { TableData } from "@/types/Table/TableData";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import FilesList from "./FilesList.vue";

@Component({
	components: {
		FilesList
	}
})
export default class FilesListModal extends Vue {
	@Prop({ type: Boolean }) showModal!: boolean;

	private isVisible = false;
	private files: TableData<CdnFile> | null = null;

	async fetchFiles(pagination: TableQuery): Promise<TableData<CdnFile>> {
		const { success, data } = await this.$files.getFiles(pagination);
		if (success) {
			return data;
		} else {
			return {
				results: [],
				count: 0,
				currentPage: 0
			};
		}
	}

	async fetchData(pagination: TableQuery) {
		this.files = await this.fetchFiles(pagination);
	}

	@Emit("onClose")
	onModalClose() {
		return;
	}

	@Watch("showModal")
	onModalVisibilityChange(newVal: boolean) {
		this.isVisible = newVal;
		if (newVal) {
			this.fetchData({
				page: 1,
				pageSize: 12,
				sortDirection: "ascending",
				sortColumn: "id",
				searchQuery: "",
				shouldSort: true
			});
		}
	}
}
