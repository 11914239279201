
import { TableQuery } from "@/api/requests/TableQuery";
import { GetProductResponse } from "@/api/responses/products/GetProductResponse";
import { TableData } from "@/types/Table/TableData";
import { Component, Vue } from "vue-property-decorator";
import ProductForm from "./ProductForm.vue";

@Component({
	components: {
		ProductForm
	}
})
export default class ProductsTable extends Vue {
	private tableColumns = [
		{
			prop: "id",
			label: "#"
		},
		{
			prop: "name",
			label: this.$t("products.Name"),
			minWidth: 200
		},
		{
			prop: "description",
			label: this.$t("products.Description"),
			minWidth: 200
		},
		{
			prop: "defaultPrice",
			label: this.$t("products.DefaultPrice"),
			minWidth: 200
		},
		{
			prop: "isGroupRpt",
			label: this.$t("products.IsAGroupReport"),
			minWidth: 200
		},
		{
			prop: "numberOfCodes",
			label: this.$t("products.NoOfKeyCodes"),
			minWidth: 200
		},
		{
			prop: "productVersionName",
			label: this.$t("products.ProductVersionName"),
			minWidth: 200
		}
	];
	private tableData = { results: [], count: 10, currentPage: 1 } as TableData<
		GetProductResponse
	>;
	private perPageOptions = [5, 10, 15];
	private loading = true;
	private modalShow = false;
	private modalHeader = "";

	$refs!: {
		productFormRef: ProductForm;
	};

	async fetchData(pagination: TableQuery): Promise<void> {
		this.loading = true;
		this.tableData.results.length = 0;
		const { data, success } = await this.$products.getProducts(pagination);
		if (success) {
			this.tableData = data;
		}
		this.loading = false;
	}

	async handleEdit(id: number): Promise<void> {
		this.modalHeader = this.$t("products.EditProduct").toString();
		this.$refs.productFormRef.fill(id);
		this.modalShow = true;
	}

	handleAdd(): void {
		this.modalHeader = this.$t("products.AddProduct").toString();
		this.$refs.productFormRef.clear();
		this.modalShow = true;
	}

	handleKeyCodes(id: number): void {
		this.$router.push({
			name: "keycodes",
			params: { productId: id.toString() }
		});
	}

	closeModal(): void {
		this.modalShow = false;
		this.fetchData({
			page: 1,
			pageSize: 5,
			sortDirection: "descending",
			sortColumn: this.tableColumns[0].prop,
			searchQuery: "",
			shouldSort: false
		} as TableQuery);
	}

	mounted(): void {
		if (this.$route.params.id) {
			this.handleEdit(+this.$route.params.id);
		}
	}
}
