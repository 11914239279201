
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SlotNavbar extends Vue {
	@Prop({ type: Boolean }) showNavbar!: boolean;
	@Prop({ type: Boolean, default: true }) transparent!: boolean;
	@Prop({ type: String, default: "absolute" }) position!: string;
	@Prop({
		type: String,
		default: "white",
		validator(value: string) {
			return [
				"white",
				"default",
				"primary",
				"danger",
				"success",
				"warning",
				"info",
				"custom"
			].includes(value);
		}
	})
	type!: string;

	get classes() {
		const color = `bg-${this.type}`;
		const navPosition = `navbar-${this.position}`;
		return [
			{ "navbar-transparent": !this.showNavbar && this.transparent },
			{ [color]: this.showNavbar || !this.transparent },
			navPosition
		];
	}
}
