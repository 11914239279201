
import { Component, Vue } from "vue-property-decorator";
import AccountsTable from "./content/AccountsTable.vue";
import AccountForm from "./content/AccountForm.vue";

@Component({
	components: {
		AccountsTable,
		AccountForm
	}
})
export default class AccountsPage extends Vue {}
