
import Notification from "./Notification.vue";

import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
	components: {
		Notification
	}
})
export default class Notifications extends Vue {
	@Prop({ type: String, default: "list" })
	transitionName!: string;
	@Prop({ type: String, default: "list" })
	transitionMode!: string;
	@Prop({ type: Boolean, default: false })
	overlap!: boolean;

	private notifications = this.$notifications.state;

	removeNotification(timestamp: Date) {
		this.$notifications.removeNotification(timestamp);
	}

	created() {
		this.$notifications.settings.overlap = this.overlap;
	}
	@Watch("overlap")
	onOverlapChanged(newVal: boolean) {
		this.$notifications.settings.overlap = newVal;
	}
}
