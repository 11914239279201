
import { Table, TableColumn, Select, Option } from "element-ui";
import Pagination from "./Pagination.vue";
import { Column } from "@/types/Table/Column";

import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { TableQuery } from "@/api/requests/TableQuery";
import { TableData } from "@/types/Table/TableData";

@Component({
	components: {
		Pagination,
		[Select.name]: Select,
		[Option.name]: Option,
		[Table.name]: Table,
		[TableColumn.name]: TableColumn
	}
})
export default class AppTable extends Vue {
	@Prop({ type: Array, required: true }) tableColumns!: Column[];
	@Prop({ type: Object, required: true }) tableData!: TableData<any>;
	@Prop({ type: Boolean, required: true }) loading!: boolean;
	@Prop({ type: String, required: true }) title!: string;
	@Prop({ type: Boolean, required: false })
	isEditable!: boolean;
	@Prop({ type: Array, required: false, default: () => [10, 25, 50] })
	perPageOptions!: number[];
	@Prop({ type: Boolean, required: false })
	disableActionsColumn!: boolean;
	@Prop({ type: Boolean, required: false }) fetchOnRemove!: boolean;

	private paginationData: TableQuery = {
		page: 1,
		pageSize: 25,
		sortDirection: "descending",
		sortColumn: this.tableColumns[0].prop,
		searchQuery: "",
		shouldSort: true
	};

	private queryTimer = 0;
	private isLoading = false;

	get to() {
		let highBound = this.from + this.paginationData.pageSize;
		if (this.tableData.count < highBound) {
			highBound = this.tableData.count;
		}
		return highBound;
	}
	get from() {
		return this.paginationData.pageSize * (this.paginationData.page - 1);
	}

	get page() {
		return this.paginationData.page;
	}
	get searchQuery() {
		return this.paginationData.searchQuery;
	}

	get pageSize() {
		return this.paginationData.pageSize;
	}

	get sortData() {
		return {
			pageSize: this.paginationData.pageSize,
			sortDirection: this.paginationData.sortDirection,
			sortColumn: this.paginationData.sortColumn
		};
	}
	mounted() {
		this.onSortChanged();
	}

	@Emit("rowSelected")
	onSelect(data: Array<any>) {
		return data;
	}

	sortChange(sortProps: any) {
		this.paginationData.page = 1;
		this.paginationData.sortDirection = sortProps.order;
		this.paginationData.sortColumn = sortProps.prop;
		this.paginationData.shouldSort = sortProps.order !== null;
	}

	@Emit("sortChanged")
	onSortChanged(pageReset = false) {
		if (pageReset) {
			this.paginationData.page = 1;
		}
		return this.paginationData;
	}

	@Watch("page")
	onPageChanged() {
		this.onSortChanged();
	}

	@Watch("pageSize")
	onPageSizeChanged() {
		this.onSortChanged(true);
	}

	@Watch("sortData")
	onSortDataChanged() {
		this.onSortChanged(false);
	}

	@Watch("searchQuery")
	onQueryChanged() {
		this.isLoading = true;
		if (this.queryTimer !== 0) {
			clearTimeout(this.queryTimer);
		}
		this.queryTimer = setTimeout(() => {
			this.isLoading = false;
			this.onSortChanged(true);
		}, 1000);
	}

	canSelectRow(row: any) {
		if (row.isSelectableRow !== undefined) {
			return row.isSelectableRow;
		}
		return true;
	}

	@Watch("fetchOnRemove")
	onPaginationRequest() {
		this.onSortChanged();
	}
}
