
import { Component, Vue } from "vue-property-decorator";
import KeyCodesTable from "./content/KeyCodesTable.vue";

@Component({
	components: {
		KeyCodesTable
	}
})
export default class KeyCodesPage extends Vue {}
