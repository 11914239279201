
import ACTIONS from "@/store/action-definitions";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, namespace } from "vuex-class";
import { TextResourceQuery } from "@/api/requests/textResource/TextResourceQuery";
import SectionModal from "./content/sections/SectionModal.vue";
import { EditableSection } from "@/types/Editor/EditableSection";
import { SaveSectionRequest } from "@/api/requests/sections/SaveSectionRequest";
import { CloneSectionRequest } from "@/api/requests/sections/CloneSectionRequest";
import { ReportSectionOrderRequest } from "@/api/requests/reports/ReportSectionOrderRequest";
import { TableQuery } from "@/api/requests/TableQuery";
import { SaveSectionContentRequest } from "@/api/requests/sectionContents/SaveSectionContentRequest";
import { GetTextResourcesResponse } from "@/api/responses/textResources/GetTextResourcesResponse";
import { TableData } from "@/types/Table/TableData";
import { GetSectionResponse } from "@/api/responses/sections/GetSectionResponse";
import { ReportSectionDto } from "@/api/responses/reports/dtos/ReportSectionDto";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import { TextResource } from "@/types/TextResource";
import { Language } from "@/types/Language";
import { GetAssessmentContext } from "@/api/responses/assessments/GetAssessmentContext";
import { AddTextResourceRequest } from "@/api/requests/textResource/AddTextResourceRequest";
import { CdnFile } from "@/types/CdnFile";
import FilesListModal from "./content/files/FilesListModal.vue";
import { ImportTextResourcesResponse } from "@/api/responses/sections/ImportTextResourcesResponse";
import { AddUpdateTextResourcesRequest } from "@/api/requests/sections/AddUpdateTextResourcesRequest";
import { RuleCollection } from "@/types/RuleCollection";
import { GetRuleCollectionsResponse } from "@/api/responses/ruleCollections/GetRuleCollectionsResponse";
import { AddRuleCollectionToSectionRequest } from "@/api/requests/ruleCollections/AddRuleCollectionToSectionRequest";
import { GetRuleSetResponse } from "@/api/responses/ruleSets/GetRuleSetResponse";
import { SaveTextResourceRequest } from "@/api/requests/textResource/SaveTextResourceRequest";
import { UpdateRuleCollectionRequest } from "@/api/requests/ruleCollections/UpdateRuleCollectionRequest";
import { ModalSectionType } from "@/types/Enums/ModalSectionType";
import { RenderReportRequest } from "@/api/requests/reports/RenderReportRequest";
import { ReportDataDto } from "@/api/responses/reports/dtos/ReportDataDto";
import { buildHtml } from "@/helpers/htmlReportBuilderHelper";

const reportEditorModule = namespace("reportEditor");

@Component({
	components: {
		SectionModal,
		FilesListModal
	}
})
export default class ReportsPage extends Vue {
	private section = {} as GetSectionResponse;
	private assessmentTypes: Array<GetAssessmentsTypeResponse> = [];
	private drag = false;
	private dialogData = {
		show: false,
		onConfirm: Function(),
		onCancel: Function(),
		message: ""
	};
	private sectionModalShow = false;
	private sectionModalType = "";
	private selectedSectionId = 0;
	private orderChanged = false;
	private editableSection: EditableSection = {
		id: 0,
		name: "",
		assessmentType: this.assessmentTypes[0],
		reportId: +this.$route.query.reportId,
		addTemplate: false
	};
	private selectedLanguages = [] as Language[];
	private languages = [] as Language[];
	private isLoadingLanguages = false;
	private showChoosenLanguages = false;
	private activeName = "1";
	private emptyReport = false;
	private filesModalShow = false;
	private files: TableData<CdnFile> | null = null;
	private importedData: ImportTextResourcesResponse[] = [];

	@Action(ACTIONS.SET_EDITOR_SECTIONS) setEditorSections: (
		sections: ReportSectionDto[]
	) => void;

	@Action(ACTIONS.SET_EDITOR_DIRTY) setEditorDirty: (
		editorDirty: boolean
	) => void;

	@Action(ACTIONS.SET_CURRENT_SECTION) setCurrentSectionId: (
		id: number
	) => void;

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	@reportEditorModule.Action(ACTIONS.SET_ASSESSMENT_CONTEXT)
	setAssessmentContext: (assessmentContext: GetAssessmentContext) => void;

	@reportEditorModule.Action(ACTIONS.SET_ASSESSMENT_TYPES)
	setAssessmentTypes: (
		assessmentTypes: Array<GetAssessmentsTypeResponse>
	) => void;

	@reportEditorModule.Action(ACTIONS.SET_PREVIEW_HTML)
	setPreviewHtml: (previewHtml: string) => void;

	@reportEditorModule.Action(ACTIONS.SET_PREVIEW_ERRORS)
	setPreviewErrors: (previewErrors: string[]) => void;

	@reportEditorModule.Action(ACTIONS.SET_SECTION_ID)
	setSectionId: (sectionId: number) => void;

	@reportEditorModule.Action(ACTIONS.SET_SECTION_NAME)
	setSectionName: (sectionName: string) => void;

	@reportEditorModule.Action(ACTIONS.SET_SECTION)
	setSection: (section: GetSectionResponse) => void;

	@Getter("getCurrentSectionId") currentSectionId!: number;
	@Getter("getEditorSections") sections!: ReportSectionDto[];

	@reportEditorModule.Getter("currentSection")
	currentSection: GetSectionResponse;

	@reportEditorModule.Getter("currentSectionContent")
	currentSectionContent: any;

	@reportEditorModule.Getter("assessmentContext")
	assessmentContext: GetAssessmentContext;

	@reportEditorModule.Getter("reportClass")
	reportClass: string;

	@Watch("currentSectionId")
	async onSectionChange(newVal: number) {
		if (newVal > 0) {
			this.section = await this.fetchSection(newVal);
			this.setSection(this.section);
			this.setSectionName(this.section.assessmentType);
		}
	}
	@Watch("sections")
	onOrderChange(newVal: ReportSectionDto[], oldVal: ReportSectionDto[]) {
		if (oldVal.length > 0) this.orderChanged = true;
	}
	handlerError(message: string): void {
		this.setMessage({
			message: message,
			type: "danger"
		});
	}
	async fetchContext(): Promise<void> {
		const { data, success } = await this.$assessment.getAssessmentContext();
		if (success) {
			this.setAssessmentContext(data);
		}
	}
	async fetchSection(id: number): Promise<GetSectionResponse> {
		const { data, success } = await this.$sections.getSection(id);
		if (success) {
			return data;
		}
		this.handlerError(this.$t("errors.FetchingSections").toString());
		return {} as GetSectionResponse;
	}
	async fetchReport(): Promise<void> {
		const { reportId } = this.$route.query;
		if (!reportId) this.$router.push({ name: "home" });
		else {
			const result = await this.$reports.getReportsById(+reportId);
			if (result.success) {
				this.emptyReport = !result.data.sections.length;
				this.setEditorSections(result.data.sections);
				this.selectedLanguages = result.data.availableLanguages.map(
					(lang) => {
						if (lang.id === result.data.defaultLanguage.id) {
							lang.name = lang.name.concat(" ★");
							lang.isDefault = true;
						} else {
							lang.isDefault = false;
						}
						return lang;
					}
				);
			}
		}
	}
	async fetchTextResourcesBySection(): Promise<TextResource[]> {
		const section = await this.fetchSection(this.currentSectionId);
		if (section) {
			return section.textResources;
		}
		this.handlerError(this.$t("errors.FetchingTextResources").toString());
		return [] as TextResource[];
	}
	async fetchAllTextResources(
		query: TextResourceQuery,
		callback?: Function
	): Promise<TableData<GetTextResourcesResponse>> {
		try {
			const { data } = await this.$textResources.getTextResources(query);
			if (callback) callback(data);
			return data;
		} catch (error) {
			this.handlerError(error);
			return {} as TableData<GetTextResourcesResponse>;
		}
	}
	async fetchAssessmentTypes(): Promise<void> {
		const { data, success } =
			await this.$assessmentsTypes.getAssessmentTypes();
		if (success) {
			data.unshift({
				id: null,
				name: "none",
				description: "none",
				assessmentStyles: []
			});
			this.setAssessmentTypes(data);
			this.assessmentTypes = data;
		} else {
			this.handlerError(
				this.$t("errors.FetchingAssessmentTypes").toString()
			);
		}
	}
	async resourceDelete(resourcesToDelete: number) {
		const { success } = await this.$sections.detachTextResources(
			this.currentSectionId,
			{
				TextResourceIds: [resourcesToDelete] as number[]
			}
		);
		if (success) {
			const section = this.currentSection;
			section.textResources = section.textResources.filter(
				(textResource: TextResource) =>
					textResource.id !== resourcesToDelete
			);

			this.setSection(section);
		}
	}
	async detachSection(sectionId: number) {
		const { success } = await this.$reports.detachSection(
			+this.$route.query.reportId,
			sectionId
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.DetachSectionSuccess"
				).toString(),
				type: "success"
			});
		}
		this.fetchReport();
		this.closeSectionModal();
	}

	async sectionSave({
		updated,
		section
	}: {
		updated: boolean;
		section: EditableSection;
	}) {
		if (this.sections.some((item) => item.name === section.name)) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.SectionNameMustBeUniqueInReport"
				).toString(),
				type: "warning"
			});
			return;
		}
		const sectionToSave: SaveSectionRequest = {
			...section,
			idAssessmentType: section.assessmentType
				? section.assessmentType.id
				: 0
		};

		let saveMethod = null;
		if (updated) saveMethod = this.$sections.updateSection(sectionToSave);
		else saveMethod = this.$sections.addSection(sectionToSave);

		const { success } = await saveMethod;

		if (success) {
			this.setMessage({
				message: this.$t("reportsEditor.SaveSectionSuccess").toString(),
				type: "success"
			});
		}
		await this.fetchReport();
		this.switchSection(this.sections[this.sections.length - 1].id);
		this.activeName = this.sections.length.toString();
		this.closeSectionModal();
	}

	async sectionRun(
		contentlanguageId: number,
		textResourceslanguageId: number,
		reportStyle: string,
		sectionContent?: SaveSectionContentRequest
	) {
		try {
			if (sectionContent) await this.sectionContentSave(sectionContent);

			const reportId = parseInt(this.$route.query["reportId"] as string);
			const response = await this.$reports.renderHtmlReport(
			{
				languageId: contentlanguageId,
				assessmentId: 0,
				reportId: reportId,
				specificSectionIds: [this.currentSectionId],
				reportStyle: reportStyle
			} as RenderReportRequest);

			if (response.status === 200) {
				let html = "";
				const data = response.data as ReportDataDto;
				if (data.errors.length) {
					this.setPreviewErrors(data.errors);
					data.errors.forEach((error: string) => {
						html += `<div class='render-error-item'>Error: ${error}</div>`;
					});
				} else html = buildHtml(data);

				this.setPreviewHtml(html);
			}
		} catch (error) {
			this.handlerError(error);
		}
	}

	async sectionContentSave(
		sectionContent: SaveSectionContentRequest,
		callback?: Function,
		confirmationRequired?: boolean
	) {
		const saveSectionContent = async () => {
			const { success } = await this.$sectionContents.saveSectionContent(
				this.section.id,
				sectionContent
			);
			if (success) {
				this.setMessage({
					message: this.$t(
						"reportsEditor.SectionContentSaveSuccess"
					).toString(),
					type: "success"
				});
				this.onSectionChange(this.section.id);
			}
		};

		if (confirmationRequired)
			this.openDialog(
				() => {
					saveSectionContent();
					callback ? callback() : Function();
					this.closeDialog();
				},
				() => {
					this.closeDialog();
					callback ? callback() : Function();
				},
				this.$t("sections.UnsavedChanges").toString()
			);
		else saveSectionContent();
	}
	openDialog(onConfirm: Function, onCancel: Function, message: string) {
		this.dialogData = {
			show: true,
			onConfirm,
			onCancel,
			message
		};
	}
	closeDialog() {
		this.dialogData.show = false;
	}
	openSectionModal(modalType: ModalSectionType) {
		this.sectionModalShow = true;
		this.sectionModalType = ModalSectionType[modalType];
	}
	closeSectionModal() {
		this.sectionModalShow = false;
	}
	async saveOrder() {
		const reportSectionOrder: ReportSectionOrderRequest = {
			reportId: +this.$route.query.reportId,
			reportSections: this.sections.map((section, index) => {
				return {
					sectionId: section.id,
					order: index
				};
			})
		};
		const { success } = await this.$reports.reorderReportSections(
			reportSectionOrder
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.SectionOrderSaveSuccess"
				).toString(),
				type: "success"
			});
		}
	}

	async sectionClone({
		sectionId,
		cloneSectionRequest
	}: {
		sectionId: number;
		cloneSectionRequest: CloneSectionRequest;
	}) {
		const { success } = await this.$sections.cloneSection(
			sectionId,
			cloneSectionRequest
		);
		if (success) {
			this.detachSection(sectionId);
			this.setMessage({
				message: this.$t(
					"reportsEditor.CloneSectionSuccess"
				).toString(),
				type: "success"
			});
		}
		this.fetchReport();
	}

	addSection() {
		if (this.currentSectionContent?.isEditorDirty) {
			this.openDialog(
				() => {
					this.openSectionModal(ModalSectionType.Create);
					this.closeDialog();
				},
				this.closeDialog,
				this.$t("table.AreYouSure").toString()
			);
			return;
		}
		this.editableSection = {
			id: 0,
			name: "",
			assessmentType: this.assessmentTypes[0],
			reportId: +this.$route.query.reportId,
			addTemplate: false
		};
		this.openSectionModal(ModalSectionType.Create);
	}
	editSection(section: ReportSectionDto) {
		this.editableSection = {
			id: section.id,
			name: section.name,
			assessmentType: this.assessmentTypes.filter(
				(type) => type.id === section.assessmentTypeId
			)[0],
			reportId: +this.$route.query.reportId,
			addTemplate: false
		};
		this.openSectionModal(ModalSectionType.Edit);
	}
	switchSection(id: number) {
		this.setCurrentSectionId(id);
		this.setSectionId(id);
		this.setPreviewErrors([]);
		this.setPreviewHtml("");
		this.dialogData.show = false;
		this.sectionRun(
			this.currentSectionContent.language.id,
			this.currentSectionContent.language.id,
			this.reportClass
		);
	}

	confirmSwitch(id: number) {
		if (id !== this.currentSectionId) {
			if (this.currentSectionContent?.isEditorDirty)
				this.openDialog(
					() => this.switchSection(id),
					this.closeDialog,
					this.$t("table.AreYouSure").toString()
				);
			else {
				this.switchSection(id);
			}
			this.selectedSectionId = id;
		}
	}
	async created() {
		await Promise.all([
			this.fetchReport(),
			this.fetchContext(),
			this.fetchAssessmentTypes()
		]);
		await this.getLanguages("");
		await this.sectionRun(
			this.languages.find((language) => language.isDefault)?.id ||
				this.languages[0].id,
			this.languages.find((language) => language.isDefault)?.id ||
				this.languages[0].id,
			"app2"
		);
	}
	destroyed() {
		this.setEditorSections([]);
	}
	get dragOptions() {
		return {
			animation: 200,
			group: "description",
			disabled: false,
			ghostClass: "ghost"
		};
	}
	async getLanguages(query: string): Promise<void> {
		this.isLoadingLanguages = true;
		const { data, success } = await this.$languages.getLanguages({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "name",
			searchQuery: query,
			shouldSort: true
		} as TableQuery);
		if (success) {
			this.languages = data.results.map((lang) => {
				if (
					lang.id ===
					(this.selectedLanguages?.find(
						(language) => language.isDefault
					)?.id ?? 0)
				) {
					lang.name = lang.name.concat(" ★");
					lang.isDefault = true;
				} else {
					lang.isDefault = false;
				}
				return lang;
			});
		}
		this.isLoadingLanguages = false;
	}

	async deleteLanguage(language: Language): Promise<void> {
		if (language.isDefault === true) {
			this.selectedLanguages.push(language);
		} else {
			const { success } = await this.$reports.deactivateLanguage(
				+this.$route.query.reportId,
				language.id
			);
			if (success) {
				this.setMessage({
					message: this.$t(
						"reportsEditor.DeleteLanguageSuccess"
					).toString(),
					type: "success"
				});
			}
		}
	}

	async addLanguage(language: Language): Promise<void> {
		const { success } = await this.$reports.activateLanguage(
			+this.$route.query.reportId,
			language.id
		);
		if (success) {
			const section = await this.fetchSection(this.currentSectionId);
			const currentSection = this.currentSection;
			currentSection.sectionContents.push(
				section.sectionContents[section.sectionContents.length - 1]
			);
			this.setSection(currentSection);
			this.setMessage({
				message: this.$t("reportsEditor.AddLanguageSuccess").toString(),
				type: "success"
			});
		}
	}

	async ruleCollectionDeleted(ruleCollectionToDelete: {
		id: number;
		index: number;
	}): Promise<void> {
		const { success } =
			await this.$ruleCollections.removeRuleCollectionFromSection(
				ruleCollectionToDelete.id,
				this.currentSectionId
			);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.DeleteRuleCollectionSuccess"
				).toString(),
				type: "success"
			});
			const ruleCollections = await this.fetchRuleCollectionBySection();
			this.section.ruleCollections = ruleCollections;
		}
	}

	async addTextResource(resource: {
		id: number;
		name: string;
		textResourceTranslations: { text: string; languageId: number }[];
	}): Promise<void> {
		const { success } = await this.$textResources.addTextResource({
			id: resource.id,
			name: resource.name,
			sectionId: this.section.id,
			textResourcesTranslations: resource.textResourceTranslations.map(
				(translation) => ({
					languageId: translation.languageId,
					text: translation.text
				})
			)
		} as AddTextResourceRequest);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.AddTextResourceSuccess"
				).toString(),
				type: "success"
			});
			const textResources = await this.fetchTextResourcesBySection();
			const section = this.currentSection;
			section.textResources.push(textResources[textResources.length - 1]);

			this.setSection(section);
		}
	}

	async exportSectionTextResources(): Promise<void> {
		const data = await this.$sections.exportTextResources(this.section.id);
		if (data) {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "SectionTextResources.csv");
			document.body.appendChild(link);
			link.click();
		}
	}

	async exportReportTextResources(): Promise<void> {
		const { reportId } = this.$route.query;
		const data = await this.$reports.exportTextResources(+reportId);
		if (data) {
			const url = window.URL.createObjectURL(new Blob([data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", "ReportTextResources.csv");
			document.body.appendChild(link);
			link.click();
		}
	}

	async openFilesModal(): Promise<void> {
		this.filesModalShow = true;
		const { success, data } = await this.$files.getFiles({
			page: 1,
			pageSize: 12,
			sortDirection: "ascending",
			sortColumn: "id",
			searchQuery: "",
			shouldSort: true
		});
		if (success) this.files = data;
	}

	async importResources(file: Blob): Promise<void> {
		const formData = new FormData();
		formData.append("file", file);
		const { success, data } = await this.$sections.importTextResources(
			formData
		);
		if (success) {
			this.importedData = data;
		}
	}

	async addImportedResources(
		dataToImport: AddUpdateTextResourcesRequest[]
	): Promise<void> {
		const { success } = await this.$sections.addUpdateTextResources(
			this.section.id,
			dataToImport
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.AddTextResourceSuccess"
				).toString(),
				type: "success"
			});
			this.section.textResources =
				await this.fetchTextResourcesBySection();
		}
	}

	async fetchAllRuleCollections(
		queryString: string,
		callback?: Function
	): Promise<TableData<GetRuleCollectionsResponse>> {
		try {
			const { success, data } =
				await this.$ruleCollections.getRuleCollections({
					page: 1,
					pageSize: 100,
					sortDirection: "ascending",
					sortColumn: "id",
					searchQuery: queryString,
					shouldSort: true
				} as TableQuery);
			if (success) {
				if (callback) callback(data);
				return data;
			}
			return {} as TableData<GetRuleCollectionsResponse>;
		} catch (error) {
			this.handlerError(error);
			return {} as TableData<GetRuleCollectionsResponse>;
		}
	}

	async addRuleCollection(
		ruleCollection: AddRuleCollectionToSectionRequest
	): Promise<void> {
		const { success } =
			await this.$ruleCollections.addRuleCollectionToSection(
				this.section.id,
				ruleCollection
			);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.AddRuleCollectionSuccess"
				).toString(),
				type: "success"
			});
			const ruleCollections = await this.fetchRuleCollectionBySection();
			const section = this.currentSection;
			section.ruleCollections.push(
				ruleCollections[ruleCollections.length - 1]
			);
			this.setSection(section);
		}
	}

	async fetchRuleCollectionBySection(): Promise<RuleCollection[]> {
		const section = await this.fetchSection(this.currentSectionId);
		if (section) {
			return section.ruleCollections;
		}
		this.handlerError(this.$t("errors.FetchingTextResources").toString());
		return [] as RuleCollection[];
	}

	async fetchRuleSets(
		queryString: string,
		callback?: Function
	): Promise<TableData<GetRuleSetResponse>> {
		try {
			const { success, data } = await this.$ruleSets.getRuleSets({
				page: 1,
				pageSize: 100,
				sortDirection: "ascending",
				sortColumn: "",
				searchQuery: queryString,
				shouldSort: false
			} as TableQuery);
			if (success) {
				if (callback) callback(data);
				return data;
			}
			return {} as TableData<GetRuleSetResponse>;
		} catch (error) {
			this.handlerError(error);
			return {} as TableData<GetRuleSetResponse>;
		}
	}

	async editTextResource(
		resourceId: number,
		resource: SaveTextResourceRequest
	): Promise<void> {
		resource.updateRules = false;
		resource.textResourcesRules = [];
		const { success } = await this.$textResources.saveTextResource(
			resourceId,
			resource
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.UpdateTextResourceSuccess"
				).toString(),
				type: "success"
			});
			this.section.textResources =
				await this.fetchTextResourcesBySection();
		}
	}

	async ruleCollectionSaved(
		ruleCollectionId: number,
		ruleCollection: UpdateRuleCollectionRequest
	): Promise<void> {
		const { success } = await this.$ruleCollections.updateRuleCollection(
			ruleCollectionId,
			ruleCollection
		);
		if (success) {
			this.setMessage({
				message: this.$t(
					"reportsEditor.UpdateRuleCollectionSuccess"
				).toString(),
				type: "success"
			});
			this.section.ruleCollections =
				await this.fetchRuleCollectionBySection();
		}
	}

	truncateText(text: string): string {
		if (text.length > 30) {
			return text.substring(0, 30) + "...";
		}
		return text;
	}
}
