
import { Component, Vue } from "vue-property-decorator";
import ReportsTable from "./content/ReportsTable.vue";

@Component({
	components: {
		ReportsTable
	}
})
export default class ReportsPage extends Vue {}
