
import { Component, Prop, Provide, Vue } from "vue-property-decorator";

@Component
export default class Sidebar extends Vue {
	@Prop({
		type: String,
		default: "black",
		validator: (value: string) => {
			const acceptedValues = [
				"",
				"white",
				"blue",
				"azure",
				"green",
				"orange",
				"red",
				"purple",
				"black"
			];
			return acceptedValues.indexOf(value) !== -1;
		}
	})
	backgroundColor!: string;

	@Prop({ type: Array, default: () => [] }) sidebarLinks!: Array<object>;
	@Prop({ type: Boolean, default: true }) autoClose!: boolean;

	@Provide("autoClose") providedAutoClose = this.autoClose;

	minimizeSidebar() {
		if (this.$sidebar) {
			this.$sidebar.toggleMinimize();
		}
	}
	beforeDestroy() {
		if (this.$sidebar.showSidebar) {
			this.$sidebar.showSidebar = false;
		}
	}
}
