
import { Vue, Component } from "vue-property-decorator";
import { Validatorable } from "@/interfaces/Validatorable";
import { GetAssessmentsTypeResponse } from "@/api/responses/assessmentsTypes/GetAssessmentsTypeResponse";
import i18n from "@/locales/i18n";
import { TableQuery } from "@/api/requests/TableQuery";
import { GetReportsResponse } from "@/api/responses/reports/GetReportsResponse";
import { GetProductResponse } from "@/api/responses/products/GetProductResponse";
import { GetSurveyQuestionGroupsResponse } from "@/api/responses/surveyQuestionGroups/GetSurveyQuestionGroupsResponse";
import { GetZohoProductResponse } from "@/api/responses/zohoProducts/GetZohoProductResponse";
import ACTIONS from "@/store/action-definitions";
import { Action } from "vuex-class";

@Component
export default class ProductForm extends Vue {
	private data: GetProductResponse = this.getEmptyProduct();
	private errorMessage = "";
	private defaultSurveyQuestionGroupsOptions = [] as GetSurveyQuestionGroupsResponse[];
	private assessmentOrderOptions = [] as GetAssessmentsTypeResponse[];
	private assessmentOrderChoosen = [] as GetAssessmentsTypeResponse[];
	private isCreateForm = false;
	private loading = false;
	private zohoProductChoosen = "";
	private reportChoosen = "";

	$refs!: {
		form: Validatorable;
	};

	@Action(ACTIONS.SET_MESSAGE) setMessage: (message: {
		message: string;
		type: string;
	}) => void;

	async checkForm(): Promise<void> {
		this.clearErrors();
		const isValid = await this.$refs.form.validate();
		if (isValid) await this.saveChanges();
	}
	async saveChanges(): Promise<void> {
		if (this.data.app4ReportId === 0) {
			this.data.app4ReportId = undefined;
		}
		this.data.assessmentOrderIds = this.assessmentOrderChoosen.map(
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			(assessmentType) => assessmentType.id!
		);
		let success = false;
		if (this.isCreateForm) {
			success = (await this.$products.createProducts(this.data)).success;
		} else {
			success = (
				await this.$products.updateProduct(this.data.id, this.data)
			).success;
		}
		if (success) {
			if (this.isCreateForm) {
				this.setMessage({
					message: i18n.t("products.SuccessfullyCreated").toString(),
					type: "success"
				});
			} else {
				this.setMessage({
					message: i18n.t("products.SuccessfullyUpdated").toString(),
					type: "success"
				});
			}
			this.$emit("closeModal");
		}
	}

	clearErrors(): void {
		this.$refs.form.reset();
	}

	getEmptyProduct(): GetProductResponse {
		return {
			id: 0,
			name: "",
			description: "",
			isGroupRpt: false,
			defaultPrice: 0.0,
			zohoProductId: 0,
			defaultSurveyQuestionGroupsIds: [] as number[],
			assessmentOrderIds: [] as number[],
			app4ReportId: undefined,
			productVersionName: "",
			numberOfCodes: 0
		} as GetProductResponse;
	}

	async getZohoProductOptions(
		queryString: string,
		callBack: (data: GetZohoProductResponse[]) => void
	): Promise<void> {
		const { data, success } = await this.$zohoProducts.getZohoProducts({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "ZohoProductId",
			searchQuery: queryString,
			shouldSort: false
		} as TableQuery);
		if (success) {
			callBack(data.results);
		}
	}

	handleSelectZohoProduct(item: GetZohoProductResponse) {
		this.zohoProductChoosen = `#${item.id} ${item.name}`;
		this.data.zohoProductId = item.id;
	}

	async getDefaultSurveyQuestionGroupsOptions(): Promise<void> {
		this.defaultSurveyQuestionGroupsOptions = [] as GetSurveyQuestionGroupsResponse[];
		const {
			data,
			success
		} = await this.$surveyQuestionGroups.getSurveyQuestionGroups();
		if (success) {
			this.defaultSurveyQuestionGroupsOptions = data;
		}
	}

	async getAssessmentOrderOptions(): Promise<void> {
		this.assessmentOrderChoosen = [] as GetAssessmentsTypeResponse[];
		this.assessmentOrderOptions = [] as GetAssessmentsTypeResponse[];
		const {
			data,
			success
		} = await this.$assessmentsTypes.getAssessmentTypes();
		if (success) {
			this.assessmentOrderOptions = data;
		}
	}

	async getReports(
		queryString: string,
		callBack: (data: GetReportsResponse[]) => void
	): Promise<void> {
		const { data, success } = await this.$reports.getReports({
			page: 1,
			pageSize: 25,
			sortDirection: "ascending",
			sortColumn: "id",
			searchQuery: queryString,
			shouldSort: true
		} as TableQuery);
		if (success) {
			callBack(data.results);
		}
	}

	handleSelectReports(item: GetReportsResponse) {
		this.reportChoosen = `#${item.id} ${item.name}`;
		this.data.app4ReportId = item.id;
	}

	async clear(): Promise<void> {
		this.loading = true;
		this.isCreateForm = true;
		await this.loadOptions();
		this.data = this.getEmptyProduct();
		this.assessmentOrderChoosen = [] as GetAssessmentsTypeResponse[];
		this.zohoProductChoosen = "";
		this.reportChoosen = "";
		this.loading = false;
	}

	async fill(id: number): Promise<void> {
		this.loading = true;
		this.isCreateForm = false;
		await this.loadOptions();
		if (id > 0) {
			this.data = this.getEmptyProduct();
			const { data, success } = await this.$products.getProductById(id);
			if (success) {
				this.data = data;
				const zohoProduct = await this.$zohoProducts.getZohoProductById(
					this.data.zohoProductId
				);
				this.zohoProductChoosen = `#${zohoProduct.data.id} ${zohoProduct.data.name}`;
				if (this.data.app4ReportId != null) {
					const report = await this.$reports.getReportsById(
						this.data.app4ReportId
					);
					this.reportChoosen = `#${report.data.id} ${report.data.name}`;
				} else {
					this.reportChoosen = "";
				}

				this.data.assessmentOrderIds.forEach((assessmentOrderId) => {
					const assessmentsTypePush = this.assessmentOrderOptions.find(
						(assessmentsType) =>
							assessmentOrderId === assessmentsType.id
					);
					if (assessmentsTypePush != null) {
						this.assessmentOrderChoosen.push(assessmentsTypePush);
					}
				});
				this.assessmentOrderOptions = this.assessmentOrderOptions.filter(
					(assessmentsType) =>
						!this.assessmentOrderChoosen.includes(assessmentsType)
				);
				this.loading = false;
			}
		}
	}

	async loadOptions(): Promise<void> {
		await this.getDefaultSurveyQuestionGroupsOptions();
		await this.getAssessmentOrderOptions();
		this.clearErrors();
	}
}
