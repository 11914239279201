
import { Component, Vue } from "vue-property-decorator";
import LanguagesTable from "./content/LanguagesTable.vue";

@Component({
	components: {
		LanguagesTable
	}
})
export default class LanguagesPage extends Vue {}
